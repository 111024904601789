import React from "react";
import Navbar from "./Navbar";

const Try = () => {
  return (
    <div name='Try'>
      <Navbar></Navbar>
      <div>Try Hello</div>
    </div>
  );
};

export default Try;
